import { GetAllParticipents, DeleteParticipentById } from "../../api/ParticipentsService"
import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { FileUpload } from 'primereact/fileupload';
import Backdiv from '../Shared/Backdiv'
import Constants from "../../Utils/Constants";
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useToastContext } from "../../context/toast-context" 
import { Spin } from "antd";
import { useNavigate } from 'react-router-dom';
import { Modal } from "antd";
import ImportParticipents  from "./ImportParticipents";
const Participant = () => {
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const [participent, setparticipent] = useState([]);
  const [visible, setVisible] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toastBC = useRef(null);

  useEffect(() => {

    async function getParticipent() {
      setSpinning(true)
      const participents = await GetAllParticipents();
      const rows = participents.map(participent => { 
        return {
          name: participent.name,
          id: participent.id,
          number: participent.number,
          conversationId: participent.conversationId,
          conversationName: participent.conversationName,
        }
      }
      );
      setparticipent(rows);
      setSpinning(false)
    }
    getParticipent()
  }, []);


  const customBase64Uploader = async (event) => {
    debugger;
    const file = event.files[0];
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

    reader.readAsDataURL(blob);

    reader.onloadend = function () {
        const base64data = reader.result;
    };
};

  const addParticipentClick = () => {
    return navigate("/addParticipent", { replace: true });
  }

  const onEditbuttonClick = (e) => {
    return navigate("/addParticipent/"+e.currentTarget.getAttribute('data-cid')+"/" + e.currentTarget.getAttribute('data-id'), { replace: true });

  }
  const actionBodyTemplate = (e) => {
    return <div className="d-flex"><Button className="table-action" type="button" data-id={e.id}  data-cid={e.conversationId} onClick={onEditbuttonClick}   rounded>Edit</Button><Button className="table-action ms-1 list-delete" type="button" data-id={e.id} data-cid={e.conversationId} onClick={confirmDelete}  rounded>Delete</Button></div>;
  };

  const clearDeletePopUp = () => {
    toastBC.current.clear();
    setVisible(false);
  };
  const deleteParticipent = async (e) => {

    let id = e.currentTarget.getAttribute('data-id');
    let cid = e.currentTarget.getAttribute('data-cid');
    const response = await DeleteParticipentById(id,cid);
    clearDeletePopUp()

    setparticipent(participent.filter(obj => obj.id != id))
    if (response && response.isSuccess) {
      showToast({
        severity: "success",
        summary: response?.message
      })
    }
    else {
      showToast({
        severity: "error",
        summary: "Something went wrong. Please try again after sometime!!"
      })
    }
  };

  const confirmDelete = (e) => {
    let id = e.currentTarget.getAttribute('data-id')
    let cid = e.currentTarget.getAttribute('data-cid')
     
    if (!visible) {
      setVisible(true);
      toastBC.current.clear();
      toastBC.current.show({
        severity: 'info',
        summary: 'Are you sure you want to delete the participants?',
        sticky: true,
        content: (props) => (
          <div className=" flex flex-column align-items-left" style={{ flex: '1' }}>
            <div className="font-medium text-lg mb-3 text-900">{props.message.summary}</div>
            <Button className="p-button-sm flex" data-id={id} data-cid={cid} label="Ok" severity="success" onClick={deleteParticipent}></Button>
          </div>
        )
      });
    }
  };
  const handleOk=()=>{

  }
  const handleCancel =()=>{
    setIsModalOpen(false);
  }
  
  const openFileUploadModal =()=>{
    setIsModalOpen(true);
  }
  return <div className="p-3">
    <Modal  footer={[]} width={1000} okText="Save" title="Import Participents" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} >
         <ImportParticipents/>
      </Modal>
    <Toast ref={toastBC} position="center" onRemove={clearDeletePopUp} />
    <Spin spinning={spinning} fullscreen style={{zIndex:1000}}/>
    <div className="card mb-4 p-3">

      {visible && <Backdiv />}
      <div className="title-header">
        <h2>Participants</h2> 
        <Button onClick={openFileUploadModal}>+ Import Participants</Button>
        <span onClick={addParticipentClick} className="add-link"><FontAwesomeIcon style={{ "font-size": "20px" }} icon={faPlusCircle} /> Add Participent</span>
      </div>
      <DataTable className="datatable"  showGridlines paginator rows={Constants.Pagination.defaultPageSize} rowsPerPageOptions={Constants.Pagination.pageSizeOption} value={participent}  >
        <Column field="name" sortable header="Name"></Column>
        <Column field="number" sortable header="Number"></Column>
        <Column field="conversationName" sortable header="Conversation"></Column>
        <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
      </DataTable>
    </div>
  </div>

}

export default Participant
