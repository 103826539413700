import { Outlet } from "react-router-dom"
import Sidebar  from "./Sidebar"
import Topbar  from "./Topbar"
import { useState } from 'react';
const Layout = ({messageCount}) => {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const openCloseSidebar =()=>{ 
        setIsSideBarOpen(!isSideBarOpen)
    }
    const onLinkClick =()=>{ 
        setIsSideBarOpen(false)
    }
    return (
        <main className="App">
            <Topbar openCloseSidebar={openCloseSidebar}/>
            <Sidebar messageCount={messageCount} openCloseSidebar={openCloseSidebar} onLinkClick={onLinkClick} isSideBarOpen={isSideBarOpen}/>
            <div className="custom-container">
            <Outlet />
            </div>
        </main>
    )
}

export default Layout
