import { useParams } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useState, useEffect } from 'react';
import { EditParticipent, AddParticipent, GetParticipentById } from "../../api/ParticipentsService"
import { GetAllConversation, GetConversationById } from "../../api/ConversationService"
import { useToastContext } from "../../context/toast-context"
import { useNavigate } from 'react-router-dom';
import { Spin } from "antd"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
const AddEditConversation = () => {
    const { showToast } = useToastContext();
    const navigate = useNavigate();
    let { cid,sid } = useParams();

    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [conversationId, setConversationId] = useState("");
    const [oldConversationId, setOldConversationId] = useState("");
    const [spinning, setSpinning] = useState("");
    const [conversations, setConversations] = useState([]);
    useEffect(() => {

        async function GetParticipent(cid,sid) { 
            const conversation = await GetParticipentById(cid,sid); 
            setName(conversation.name)
            setNumber(conversation.number) 
            setOldConversationId(conversation.conversationId) 
        }
       
        async function GetConversation() {
            setSpinning(true)
            if (cid&&sid) {
                await GetParticipent(cid,sid)
            }
            const conversation = await GetAllConversation(); 
            var conversations=conversation.map(conversation => {
                return {
                    name: conversation.conversationFriendlyName,
                    id: conversation.conversationSid
                }
            })
            setConversations(conversations)

            setConversationId(conversations.find((element) => element.id == cid))
            setSpinning(false)
        }
        GetConversation();

    }, []);


    const submit = async (e) => {
        e.preventDefault(); 
        setSpinning(true)
        if (cid&&sid) {
            var response = await EditParticipent({
                id: sid,
                oldConversationId: oldConversationId,
                name: name,
                mobileNo: number,
                conversationId: conversationId.id,
            });
        }
        else {
            var response = await AddParticipent({
                name: name,
                mobileNo: number,
                conversationId: conversationId.id,
            });
        }
        if (response?.isSuccess) {

            showToast({
                severity: "success",
                summary: response?.message
            })
            navigate("/participant", { replace: true })
        }
        else {
            showToast({
                severity: "error",
                summary: response?.message
            })
        }
        setSpinning(false);
    }
    const goback = () => {
        navigate("/participant", { replace: true })
    }
    return (

        <div className="add-user-cover p-3 d-flex justify-content-center"> 
            <div className="add-user-cover-card card mt-4 p-3" style={{ maxWidth: "625px" }}>
                <h2>{sid ? "Edit" : "Add"}  Participant</h2>
                <form onSubmit={submit} autoComplete="new-password">
                    <div className="row mt-1">
                        <div className="form-group mt-3 col-12">
                            <label  >Conversation </label>
                            <Dropdown required={true} value={conversationId} onChange={(e) => setConversationId(e.value)} options={conversations} optionLabel="name" placeholder="Select a conversation" className="w-100 mt-1" />
                        </div>
                        <div className="form-group mt-3 col-12">
                            <label  >Name</label>
                            <InputText required={true} value={name} onChange={(e) => { setName(e.target.value) }} className="w-100 mt-1" placeholder="Name" />
                        </div>


                        <div className="form-group mt-3 col-12">
                            <label  >Number</label>
                            <InputText required={true} value={number} onChange={(e) => { setNumber(e.target.value) }} className="w-100 mt-1" placeholder="Number" />
                        </div>

                        <div className="form-group mt-3 col-md-12">
                        <button disabled={spinning} className="button button-primary" >  { spinning?<FontAwesomeIcon icon={faCircleNotch} spin/>:"Save"}</button>
                            <button type="button" onClick={goback} className="ms-1 button button-outline-danger">Back</button>

                        </div>

                    </div>
                </form>
            </div>

        </div>)
}
//<Password value={value} onChange={(e) => setValue(e.target.value)} toggleMask />
export default AddEditConversation;