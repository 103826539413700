import { useParams } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { useState, useEffect } from 'react';
import { EditConversation, AddConversation, GetConversationById } from "../../api/ConversationService"
import { useToastContext } from "../../context/toast-context"
import { useNavigate } from 'react-router-dom';
import { Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
const AddEditConversation = () => {
    const { showToast } = useToastContext();
    const navigate = useNavigate();
    let { id } = useParams();

    const [name, setName] = useState("");
    const [spinning, setSpinning] = useState(false);
    useEffect(() => {

        async function getConversation(id) {
            setSpinning(true)
            const conversation = await GetConversationById(id);
            setName(conversation.friendlyName)
            setSpinning(false)
        }
        if (id) {
            getConversation(id)
        }
    }, []);


    const submit = async (e) => {
        setSpinning(true)
        e.preventDefault();

        if (id) {
            var response = await EditConversation({
                id: id,
                name: name,
            });
        }
        else {
            var response = await AddConversation({
                name: name,
            });
        }
        if (response?.isSuccess) {

            showToast({
                severity: "success",
                summary: response?.message
            })
            navigate("/conversation", { replace: true })
        }
        else {
            showToast({
                severity: "error",
                summary: response?.message
            })
        }
        setSpinning(false)
    }
    const goback = () => {
        navigate("/conversation", { replace: true })
    }
    return (

        <div className="add-user-cover p-3 d-flex justify-content-center">
             <div className="add-user-cover-card card mt-4 p-3" style={{ maxWidth: "625px" }}>
                <h2>{id ? "Edit" : "Add"}  Conversation</h2>
                <form onSubmit={submit} autoComplete="new-password">
                    <div className="row mt-1">
                        <div className="form-group mt-3 col-12">
                            <label  >Name</label>
                            <InputText required={true} autoComplete="new-password" value={name} onChange={(e) => { setName(e.target.value) }} className="w-100 mt-1" placeholder="Name" />
                        </div>

                        <div className="form-group mt-3 col-md-12">
                            <button disabled={spinning} className="button button-primary" >  { spinning?<FontAwesomeIcon icon={faCircleNotch} spin/>:"Save"}</button>
                            <button type="button" onClick={goback} className="ms-1 button button-outline-danger">Back</button>

                        </div>

                    </div>
                </form>
            </div>

        </div>)
}
//<Password value={value} onChange={(e) => setValue(e.target.value)} toggleMask />
export default AddEditConversation;