
import axios from './axios';
import { getAuth } from '../hooks/useAuth'
const GetAllConversation = async () => {
    try { 
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.get("/conversation/getAllConversations", {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });  
        return response?.data?.conversations ?? [];
    } catch (err) {
    }
    return [];
}
const GetConversationById = async (id) => {

    try { 
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.get("/conversation/getConversationById/" + id, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        }); 
        return response?.data?.conversation ?? {};
    } catch (err) {
         
    }
    return {};
}
const DeleteConversationById = async (id) => {

    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.delete("/conversation/deleteConversation/" + id, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });
        return response?.data;
    } catch (err) {
    }
    return {};
}
const AddConversation = async (data) => {

    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.post("/conversation/addConversation", data, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });
        return response?.data;
    } catch (err) {
    }
    return;
}
const EditConversation = async (data) => {

    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.post("/conversation/updateConversation", data, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });
        return response?.data;
    } catch (err) {
    }
    return;
}
const SendConversation = async (data) => { 
    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.post("/conversation/sendMessage", data, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });
        return response?.data;
    } catch (err) {
        console.log(err);
    }
    return;
}
const GetChatAccessToken = async () => {
    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.get("/conversation/chatAccessToken",
            {
                headers: { 'Content-Type': 'application/json','authorization': 'Bearer ' + token }
            });
        return response?.data;
    } catch (err) { 
        console.log(err);
    }
    return "";
}
export {GetChatAccessToken, SendConversation, EditConversation, AddConversation, DeleteConversationById, GetConversationById, GetAllConversation }