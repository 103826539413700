 
//import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/custom-primereact.css';
import './css/custom-boostrap.css';
import 'primeicons/primeicons.css';
import App from './App'; 
import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import { ToastContextProvider } from "./context/toast-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render( 
     <ToastContextProvider>
    <BrowserRouter> 
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes> 
    </BrowserRouter>
    </ToastContextProvider> 
);
 