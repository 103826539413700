
import React, { useRef, useState,useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag'; 
import { BulkImport } from "../../api/ParticipentsService"
import { GetAllConversation } from "../../api/ConversationService"
import { Spin } from "antd";

export default function ImportParticipents() {
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const [spinning, setSpinning] = useState(false);
    const fileUploadRef = useRef(null);
    
    const [conversationId, setConversationId] = useState("");
    
    const [isError, setIsError] = useState(false);
    const [conversations, setConversations] = useState([]);
    useEffect(() => {

         
       
        async function GetConversation() {
            setSpinning(true)
         
            const conversation = await GetAllConversation(); 
            var conversations=conversation.map(conversation => {
                return {
                    name: conversation.conversationFriendlyName,
                    id: conversation.conversationSid
                }
            })
            setConversations(conversations)
            setSpinning(false)
        }
        GetConversation();

    }, []);
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload =  async ({files}) => {
        const [file] = files; 
        if(!conversationId)
        {
            setIsError(true);
            return;
        }
        setSpinning(true)
        let responseData=await BulkImport(file,conversationId.id)
        setSpinning(false)
        if(responseData && responseData.isSuccess){
            toast.current.show({ severity: 'info', summary: 'success    ', detail: responseData.message });
            window.location.reload(); 
        }
        else 
            toast.current.show({ severity: 'error', summary: 'Error', detail:  responseData?.message??'Someting went wrong. Please try again after sometime!' });
        
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton } = options;
        
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton} 
                <Dropdown required={true} value={conversationId} onChange={(e) => setConversationId(e.value)} options={conversations} optionLabel="name" placeholder="Select a conversation" />
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap" style={{display:"flex",justifyContent: "space-between"}}>  
                   
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                   
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{display:"flex"}}>
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images',  className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload',   className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
             <Spin spinning={spinning} fullscreen style={{zIndex:1000}}/>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
            <ul  style={{color:"red",display:isError?"block":"none"}}><li>Please select Conversation</li></ul>
            <FileUpload ref={fileUploadRef} name="demo[]"   accept=".xlsx" maxFileSize={10000000}
            customUpload={true}
            uploadHandler={onTemplateUpload}  onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}  />
        </div>
    )
}
        