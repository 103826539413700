
import axios from './axios';
import {getAuth} from '../hooks/useAuth'
const GetAllUsers = async () => {  
    try {
        const auth  = getAuth(); 
        const token = auth["accessToken"];
        const response = await axios.get("/user/getAllUsers",{
            headers: { 'Content-Type': 'application/json', 'authorization':'Bearer '+token}
        });
        return response?.data;
    } catch (err) { 
    }
    return [];
}
const GetUserById = async (id) => {

    try {
        const auth  = getAuth(); 
        const token = auth["accessToken"];
        const response = await axios.get("/user/getUser/"+id,{
            headers: { 'Content-Type': 'application/json', 'authorization':'Bearer '+token}
        });
        return response?.data;
    } catch (err) { 
    }
    return {};
 }
const DeleteUserById = async (id) => {

    try {
        const auth  = getAuth(); 
        const token = auth["accessToken"];
        const response = await axios.delete("/user/deleteUser/"+id,{
            headers: { 'Content-Type': 'application/json', 'authorization':'Bearer '+token}
        });
        return response?.data;
    } catch (err) { 
    }
    return {};
 }
 const AddEditUser = async (data) => {

    try {
        const auth  = getAuth(); 
        const token = auth["accessToken"];
        const response = await axios.post("/user/addUpdateUser",data,{
            headers: { 'Content-Type': 'application/json', 'authorization':'Bearer '+token}
        });
        return response?.data;
    } catch (err) { 
    }
    return;
 }
export {GetAllUsers,GetUserById,AddEditUser,DeleteUserById}