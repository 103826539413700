import { useParams } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useState, useRef, useEffect } from 'react';
import { getRoleList, getRoleByRoleId } from "../../Utils/utils";
import { AddEditUser, GetUserById } from "../../api/UserService"
import { useToastContext } from "../../context/toast-context"
import { useNavigate } from 'react-router-dom';
import { Spin } from "antd"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
const AddUser = () => {
    const { showToast } = useToastContext();
    const navigate = useNavigate();
    let { id } = useParams();
    const refPassword = useRef(null);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [spinning, setSpinning] = useState("");
    const [status, setStatus] = useState(null);

    const [showPassword, setShowPassword] = useState(true);
    useEffect(() => {

        async function getUser(id) {
            setSpinning(true)
            const user = await GetUserById(id);
            setFirstName(user.firstName)
            setLastName(user.lastName)
            setEmail(user.email)
            setMobileNo(user.mobileNo)
            setStatus(user.IsActive ? { name: 'Active', code: 1 } : { name: 'Inactive', code: 0 })
            setRole({ name: getRoleByRoleId(user.userType), code: user.userType })
            setSpinning(false)

        }
        if (id) {
            getUser(id)
            setShowPassword(false);
        }
    }, []);

    const lstStatus = [
        { name: 'Active', code: 1 },
        { name: 'Inactive', code: 0 }
    ];



    const submit = async (e) => {
        e.preventDefault();
        setSpinning(true)
        var response = await AddEditUser({
            id: id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            mobileNo: mobileNo,
            userType: role.code,
            IsActive: status.code,
        });
        setSpinning(false)
        if (response?.isSuccess) {

            showToast({
                severity: "success",
                summary: response?.message
            })
            navigate("/user", { replace: true })
        }
        else {
            showToast({
                severity: "error",
                summary: response?.message
            })
        }
    }
    const goback = () => {
        navigate("/user", { replace: true })
    }
    return (

        <div className="add-user-cover p-3">
             <div className="add-user-cover-card card mt-4 p-3">
                <h2>{id ? "Edit" : "Add"}  User</h2>
                <form onSubmit={submit} autoComplete="new-password">
                    <div className="row mt-1">
                        <div className="form-group mt-3 col-md-6">
                            <label  >First Name</label>
                            <InputText required={true} autoComplete="new-password" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} className="w-100 mt-1" placeholder="First Name" />
                        </div>
                        <div className="form-group mt-3 col-md-6">
                            <label  >Last Name</label>
                            <InputText required={true} autoComplete="   " value={lastName} onChange={(e) => setLastName(e.target.value)} className="w-100 mt-1" placeholder="Last Name" />
                        </div>
                        <div className="form-group mt-3 col-md-6">
                            <label  >Email</label>
                            <InputText required={true} autoComplete="off" value={email} onChange={(e) => setEmail(e.target.value)} className="w-100 mt-1" placeholder="Email" />
                        </div>
                        {showPassword &&
                            <div className="form-group mt-3 col-md-6">
                                <label  >Password</label>
                                <InputText ref={refPassword} required={true} autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="w-100 mt-1" placeholder="Password" />

                            </div>
                        }
                        <div className="form-group mt-3 col-md-6">
                            <label  >Mobile No</label>
                            <InputText required={true} autoComplete="off" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} className="w-100 mt-1" placeholder="Mobile No" />

                        </div>
                        <div className="form-group mt-3 col-md-6">
                            <label  >Role </label>
                            <Dropdown required={true} value={role} onChange={(e) => setRole(e.value)} options={getRoleList()} optionLabel="name" placeholder="Select a Role" className="w-100 mt-1" />
                        </div>
                        <div className="form-group mt-3 col-md-6">
                            <label  >Status</label>
                            <Dropdown required={true} value={status} onChange={(e) => setStatus(e.value)} options={lstStatus} optionLabel="name" placeholder="Select a Status" className="w-100 mt-1" />
                        </div>
                        <div className="form-group mt-3 col-md-12">
                        <button disabled={spinning} className="button button-primary" >  { spinning?<FontAwesomeIcon icon={faCircleNotch} spin/>:"Save"}</button>
                          <button type="button" onClick={goback} className="ms-1 button button-outline-danger">Back</button>

                        </div>

                    </div>
                </form>
            </div>

        </div>)
}
//<Password value={value} onChange={(e) => setValue(e.target.value)} toggleMask />
export default AddUser;