import React from 'react';
import { List, Typography,Modal,Button,Input,Spin } from "antd";

import { DeleteConversationById } from "../api/ConversationService"
import { GetAllParticipentsById,DeleteParticipentById,AddParticipentBySid } from "../api/ParticipentsService"
import conversationsListStyles from "../assets/ConversationsList.module.css";
import conversationsItemStyles from "../assets/ConversationsItem.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan,faUserPlus} from '@fortawesome/free-solid-svg-icons'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { joinClassNames } from "../Utils/class-name";

const { Text } = Typography;
const { confirm } = Modal;
export class ConversationsList extends React.Component {
    
  constructor(props) {
    super(props);   
    this.state = { 
      isModalOpen:false, 
      isParticipantsModalOpen:false, 
      participants:[],
      participantName:"",
      participantNumber:"",
      conversationSid:"",
      spinning : false,
      addErrorMessage:false
      
    };
  }
    deleteConversation = async (e) => {

        let id = e.currentTarget.getAttribute('data-id');
        
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure to delete this conversation?</span>,
            onOk:async()=>{
                const response = await DeleteConversationById(id);
            },
            onCancel() {
              console.log('Cancel');
            },
          })
      };
      
    deleteParticipents = async (e) => {

        let id = e.currentTarget.getAttribute('data-id');
        
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure to delete this participant?</span>,
            onOk:async()=>{
                const response = await DeleteParticipentById(id);
                if(response?.isSuccess)
                {
                    this.setState({  
                        participants:[...this.state.participants.filter((it) => it.id !=id)]
                    }); 
                }
            },
            onCancel() {
              console.log('Cancel');
            },
          })
      };



      showModal = async (e) => { 
        let id = e.currentTarget.getAttribute('data-id');
        let participants=await GetAllParticipentsById(id);  
        this.setState({ 
          isModalOpen: true,
          participants:participants,
          conversationSid:id
        }); 
      }; 
       handleCancel = () => {
        this.setState({ 
          isModalOpen: false,
          conversationSid:""
        }); 
      };

      showParticipantsModal = async (e) => {  
        this.setState({ 
            isParticipantsModalOpen: true
        }); 
      };
    
       handleParticipantsOk = async () => {
         
      if(this.state.participantNumber ==undefined || this.state.participantNumber ==null || this.state.participantNumber =='' 
        || this.state.participantName == undefined || this.state.participantName == null || this.state.participantName == "")
      {
        this.setState({
           
        addErrorMessage:true
      })
      }
      else
      {

      

        this.setState({
            isParticipantsModalOpen: false,
          spinning:true,
          addErrorMessage:false
        })
         let response = await AddParticipentBySid({
            conversationId:this.state.conversationSid, mobileNo:this.state.participantNumber, name:this.state.participantName
         });

         if(response?.isSuccess)
         {
            let participants=await GetAllParticipentsById(this.state.conversationSid); 
            this.setState({  
                participants:participants, 
              }); 
         }

          this.setState({  
            spinning:false,
            participantName:"",
            participantNumber:""
          });  
      }
        
      };
    
       handleParticipantsCancel = () => {
        this.setState({ 
            isParticipantsModalOpen: false
        }); 
      };
      onParticipantName = (event)=>{
        this.setState({
            participantName:event.target.value
        })
      }
      onParticipantNumber = (event)=>{
        this.setState({
            participantNumber:event.target.value
        })
      }
    render() {
        const { conversations, selectedConversationSid, onConversationClick,stopSpinning } = this.props;

        return (
            <div>
                <Spin spinning={this.state.spinning} fullscreen style={{zIndex:10000}}/>
                      <Modal okText="Save" title="Add New Participants" open={this.state.isParticipantsModalOpen} onOk={this.handleParticipantsOk} onCancel={this.handleParticipantsCancel}>
                      <ul style={{display:this.state.addErrorMessage?"block":"none",color:"red"}}>
                        <li> Please fill details.</li>
                      </ul>
      <Input
                            style={{flexBasis: "100%"}}
                            placeholder={"Name"}
                            type="text"  
                            autoComplete={"off"} 
                            onChange={this.onParticipantName}
                            value={this.state.participantName}
                        />
                         <Input
                            style={{flexBasis: "100%"}}
                            className='mt-2'
                            placeholder={"Number"}
                            type="text"  
                            autoComplete={"off"} 
                            onChange={this.onParticipantNumber}
                            value={this.state.participantNumber}
                        />
      </Modal>

      <Modal footer={<Button type='primary' onClick={this.showParticipantsModal}>Add New Participant</Button>}  title="Participants" open={this.state.isModalOpen}  onCancel={this.handleCancel}>
                    
                    <List  
              style={{maxHeight:"250px"}}
              className="list-participents"
              dataSource={this.state.participants}
              renderItem={item => {
                  return (
                    <List.Item
                          key={item.id} 
                          bordered={true} 
                           
                      >
                          <Text
                              strong 
                          >
                              {item.name }
                          </Text>
                          <Text
                              strong 
                          >
                              {item.number }
                          </Text>
                          <div>  
                          <i onClick={this.deleteParticipents} data-id={item.id} className="chat-list-delete-add-icon"  >
                          <FontAwesomeIcon icon={faTrashCan} />
                          </i>
                          </div>
                      </List.Item> )
              }} />
    </Modal>
              
            <List 
                header={    <button className="button button-primary" style={{lineHeight:"0",width: "100%",padding: "20px",borderRadius: 0}} > Conversation List</button>}
                id='chat-list-inner'
                className={conversationsListStyles['conversations-list']}
                bordered={true}
                loading={conversations.length === 0 && !stopSpinning}
                dataSource={conversations}
                renderItem={item => {
                    const activeChannel = item.sid === selectedConversationSid;
                    const conversationItemClassName = joinClassNames([
                        conversationsItemStyles['conversation-item'],
                        activeChannel && conversationsItemStyles['conversation-item--active']
                    ]);

                    return (
                        <List.Item
                            key={item.sid}
                            onClick={() => onConversationClick(item)}
                            className={conversationItemClassName}
                        >
                            <Text
                                strong
                                className={conversationsItemStyles['conversation-item-text']}
                            >
                                {item.friendlyName || item.sid}
                            </Text>
                            <div> 
                            <i onClick={this.showModal} data-id={item.sid} title='Add Participant'   style={{padding: "8px 9px 9px 9px"}}><FontAwesomeIcon icon={faUserPlus} />
                            </i> 
                            <i  title='Delete Conversation' onClick={this.deleteConversation} data-id={item.sid} className="chat-list-delete-add-icon"  >
                            <FontAwesomeIcon icon={faTrashCan} />
                            </i>
                            </div>
                        </List.Item>
                    )
                }}
            />
            </div>
        )
    }
}
