import { GetAllConversation, DeleteConversationById } from "../../api/ConversationService"
import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { getRoleByRoleId } from '../../Utils/utils'
import Backdiv from '../Shared/Backdiv'
import Constants from "../../Utils/Constants";
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useToastContext } from "../../context/toast-context"
import { Spin } from "antd";

import { useNavigate } from 'react-router-dom';
const Conversation = () => {
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const [conversation, setconversation] = useState([]);
  const [visible, setVisible] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const toastBC = useRef(null);

  useEffect(() => {

    async function getConversation() {
      setSpinning(true)
      const conversations = await GetAllConversation(); 
      const rows = conversations.map(conversation => {
        return {
          name: conversation.conversationFriendlyName,
          id: conversation.conversationSid
        }
      }
      );
      setconversation(rows);
      setSpinning(false)
    }
    getConversation()
  }, []);



  const addConversationClick = () => {
    return navigate("/addConversation", { replace: true });
  }

  const onEditbuttonClick = (e) => {
    return navigate("/addConversation/" + e.currentTarget.getAttribute('data-id'), { replace: true });

  }
  const actionBodyTemplate = (e) => {
    return <div className="d-flex"><Button className="table-action" type="button" data-id={e.id} onClick={onEditbuttonClick}  rounded>Edit</Button><Button className="table-action ms-1 list-delete" type="button" data-id={e.id} onClick={confirmDelete}  
    rounded>Delete</Button></div>;
  };

  const clearDeletePopUp = () => {
    toastBC.current.clear();
    setVisible(false);
  };
  const deleteConversation = async (e) => {

    let id = e.currentTarget.getAttribute('data-id');
    const response = await DeleteConversationById(id);
    clearDeletePopUp()

    setconversation(conversation.filter(obj => obj.id != id))
    if (response && response.isSuccess) {
      showToast({
        severity: "success",
        summary: response?.message
      })
    }
    else {
      showToast({
        severity: "error",
        summary: "Something went wrong. Please try again after sometime!!"
      })
    }
  };

  const confirmDelete = (e) => {
    let id = e.currentTarget.getAttribute('data-id')
    if (!visible) {
      setVisible(true);
      toastBC.current.clear();
      toastBC.current.show({
        severity: 'info',
        summary: 'Are you sure you want to delete the conversation?',
        sticky: true,
        content: (props) => (
          <div className=" flex flex-column align-items-left" style={{ flex: '1' }}>
            <div className="font-medium text-lg mb-3 text-900">{props.message.summary}</div>

            <Button className="p-button-sm flex" data-id={id} label="Ok" severity="success" onClick={deleteConversation}></Button>
          </div>
        )
      });
    }
  };

  return <div className="p-3">
     <Spin spinning={spinning} fullscreen style={{zIndex:1000}}/>
    <Toast ref={toastBC} position="center" onRemove={clearDeletePopUp} />
    <div className="card mb-4 p-3">

      {visible && <Backdiv />}
      <div className="title-header">
        <h2>Conversations</h2>
        <span onClick={addConversationClick} className="add-link"><FontAwesomeIcon style={{ "font-size": "20px" }} icon={faPlusCircle} /> Add Conversation</span>
      </div>
      <DataTable className="datatable" showGridlines paginator rows={Constants.Pagination.defaultPageSize} rowsPerPageOptions={Constants.Pagination.pageSizeOption} value={conversation}  >
        <Column field="name" sortable header="Name"></Column>
        <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
      </DataTable>
    </div>
  </div>

}

export default Conversation
