import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate(); 
    const goBack = () => navigate(-1);

    return (
        <div>
           <img src="/images/error-403.png" />
        </div>
    )
}

export default Unauthorized
