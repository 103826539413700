import { useRef, useState, useEffect } from 'react';
import {setAuth} from '../../hooks/useAuth';
import  './login.css';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from '../../api/axios';
const LOGIN_URL = '/account/login';

const Login = () => { 
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email:user,password:pwd }),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            console.log(JSON.stringify(response?.data));
            const accessToken = response?.data?.token;
            const roles = [response?.data?.userType]; 
            setAuth({ user, pwd, roles, accessToken,email:response?.data?.email });
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Invalid Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (

 <div className='loginPage'>
        <div className="wrapper">
  <div className="inner-warpper ">
    <h2 className="title" style={{textAlign:"center"}}>SMS</h2>
    <h4 className="title">Login</h4>
    <form action="" onSubmit={handleSubmit}>
    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
      <div className="input-group"> 
  
        <input className="form-control" name="userName" id="userName" type="text" placeholder="Email" ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user} /> 
      </div>
      <div className="input-group"> 
        <input className="form-control" placeholder="Password"  type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required /> 
      </div>

      <button type="submit" className='button button-primary loginPagebutton' id="login">Login</button>
      
    </form>
  </div>
</div>  
</div>

    )
}

export default Login
