const Roles = {
    "Administrator": 1,
    "Client": 2,
};
const Pagination =
{
    pageSizeOption: [10, 20, 30, 40, 50],
    defaultPageSize: 10

}
const apiUrl="https://smsapi.echotxtmsg.com";
const Constants = {
    Roles,
    Pagination,
    apiUrl
}

export default Constants;