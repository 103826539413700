import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Topbar = (prop) => { 
    return (
        <div className="topbar"> 
        <FontAwesomeIcon onClick={prop.openCloseSidebar} className='topbar-icon' size="xl" icon={faBars} />
        <h1 className='m-0'>SMS</h1>
        </div>
    )
}
//    <i className="sidebar-link icon" data-column="/sendMessage" onClick={clickLink}><FontAwesomeIcon icon={faPaperPlane} /></i>
            

export default Topbar;
