
import axios from './axios';
import { getAuth } from '../hooks/useAuth' 

const GetAllParticipents = async () => {
    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.get("/participant/getAllParticipants", {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        }); 
        return response?.data?.conversations ?? [];
    } catch (err) {
    }
    return [];
}
const GetAllParticipentsById = async (sid) => {
    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.get("/participant/GetAllParticipentsById/"+sid, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });
        return response?.data?.conversations ?? [];
    } catch (err) { 
    }
    return [];
}
const GetParticipentById = async (cid,sid) => {

    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.get("/participant/getParticipantById/"+cid+"/" + sid, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        }); 
        return response?.data?.conversation ?? {};
    } catch (err) { 
    }
    return {};
}
const DeleteParticipentById = async (id,cid) => {

    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.delete("/participant/deleteParticipant/"+cid+"/" + id, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });
        return response?.data;
    } catch (err) {
    }
    return {};
}
const AddParticipent = async (data) => {

    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.post("/participant/addParticipant", data, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });
        return response?.data;
    } catch (err) {
    }
    return;
}

const AddParticipentBySid = async (data) => {

    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.post("/participant/addParticipantBySid", data, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });
        return response?.data;
    } catch (err) {
    }
    return;
}
const EditParticipent = async (data) => {

    try {
        const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.post("/participant/updateParticipant", data, {
            headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
        });
        return response?.data;
    } catch (err) {
    }
    return;
}
const BulkImport = async (fileData,id) => {
    try {
    let formData = new FormData();
    formData.append('file', fileData);
    formData.append('cid', id);

    const auth = getAuth();
        const token = auth["accessToken"];
        const response = await axios.post("/participant/bulkUpload", formData, {
            headers: { 'Content-Type': 'multipart/form-data', 'authorization': 'Bearer ' + token }
        });
        return response?.data;
    } catch (err) {
        return err.response.data
    }
    return {};
};

export {BulkImport,AddParticipentBySid, GetAllParticipentsById, EditParticipent, AddParticipent, DeleteParticipentById, GetParticipentById, GetAllParticipents }