
import Cookies from 'universal-cookie';
const cookies = new Cookies();

 const getAuth = ()=>{
return cookies.get('authUser');
}


const setAuth = (data)=> {
        let d = new Date();
        d.setTime(d.getTime() + (50*60*1000));
      
        cookies.set("authUser", data, {path: "/", expires: d});
};
const removeAuth = ()=> { 
        cookies.remove("authUser");
};
      

export {getAuth,setAuth,removeAuth};