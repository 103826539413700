import React from "react";

import {getAuth} from "../hooks/useAuth";
import { useEffect,useState,useRef } from 'react';
import { Badge, Button, Layout, Spin, Typography,Modal,Input } from "antd";
import { Client as ConversationsClient } from "@twilio/conversations";
import { Toast } from "primereact/toast";
import { GetChatAccessToken } from "../api/ConversationService"
import Icon from '@ant-design/icons';
import "../assets/Conversation.css";
import messageSound from "../assets/message.mp3";
import "../assets/ConversationSection.css";
import { ReactComponent as Logo } from "../assets/twilio-mark-red.svg";
import { EditConversation, AddConversation, GetConversationById } from "../api/ConversationService"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faOutdent,faTimes} from '@fortawesome/free-solid-svg-icons'
import Conversation from "./Conversation";
import LoginPage from "./LoginPage";
import { ConversationsList } from "./ConversationsList";
import { HeaderItem } from "./HeaderItem";
import { faAngleDoubleUp, faL } from "@fortawesome/free-solid-svg-icons";
const { Content, Sider, Header } = Layout;
const { Text } = Typography; 
class ConversationsApp extends React.Component {
 
  constructor(props) {
    super(props); 
    let auth = getAuth();
    const name = auth.email || "";
    const loggedIn = name !== "";
     
    this.state = {
      name: name,
      loggedIn: true,
      token: null,
      statusString: null,
      conversationsReady: false,
      conversations: [],
      selectedConversationSid: null,
      newMessage: "",
      isModalOpen:false,
      conversationName:"",
      spinning:false,
      addErrorMessage:false,
      stopSpinning:false,
      isChatSideBarOpen:false
      
    };
  }
 
  toggaleSideBar=()=>
  {
    this.setState({ isChatSideBarOpen: !this.state.isChatSideBarOpen });
  }
  componentDidMount = () => {
    if (this.state.loggedIn) {
      this.getToken();
      this.setState({ statusString: "Fetching credentials…" });
    }
  };

  logOut = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      name: "",
      loggedIn: false,
      token: "",
      conversationsReady: false,
      messages: [],
      newMessage: "",
      conversations: [],
      isModalOpen:false,
      stopSpinning:false
    });

    localStorage.removeItem("name");
    this.conversationsClient.shutdown();
  };

  getToken = async () => {
    const token = await GetChatAccessToken();
    this.setState({ token: token }, this.initConversations);
  };

  initConversations = async () => {
    window.conversationsClient = ConversationsClient;
    this.conversationsClient = new ConversationsClient(this.state.token);
    this.setState({ statusString: "Connecting to Twilio…" });

    this.conversationsClient.on("connectionStateChanged", async (state) => {

      if (state === "connecting")
        this.setState({
          statusString: "Connecting to Twilio…",
          status: "default"
        });
      if (state === "connected") {  

        let conversationsList =(await this.conversationsClient.getSubscribedConversations()).items
        if(conversationsList.length<=0)
        {
          this.setState({stopSpinning:true})
        }
        else
        {
          this.setState({ selectedConversationSid: conversationsList[0].sid });
        } 

        this.getAllMessageCount(true);
          for(let i=0;i<conversationsList.length;i++) { 
           
              conversationsList[i].on('messageAdded', message => { 
                if (message.author !== this.state.name) {   
                  this.getAllMessageCount();
                 }
              }); 
         
           };
 
        this.setState({ conversations:conversationsList });
        this.setState({
          statusString: "You are connected.",
          status: "success"
        });
      }
      if (state === "disconnecting")
        this.setState({
          statusString: "Disconnecting from Twilio…",
          conversationsReady: false,
          status: "default"
        });
      if (state === "disconnected")
        this.setState({
          statusString: "Disconnected.",
          conversationsReady: false,
          status: "warning"
        });
      if (state === "denied")
        this.setState({
          statusString: "Failed to connect.",
          conversationsReady: false,
          status: "error"
        });
    });
    this.conversationsClient.on("conversationJoined", (conversation) => {
      this.setState({ conversations: [conversation,...this.state.conversations] });
    });
     
    this.conversationsClient.on("conversationLeft", (thisConversation) => {
      let tempList=this.state.conversations.filter((it) => it !== thisConversation);
      if(tempList.length>0)
      {
        this.setState({
          stopSpinning:true
        });
      }
      this.setState({
        conversations: [...tempList]
      });
    });
    this.conversationsClient.on('initFailed', ({ error }) => {
    });

  };
  getAllMessageCount= async(start)=>{
     
     let conversations=(await this.conversationsClient.getSubscribedConversations()).items;

      let totalMessageCount=0;
      for(let i=0;i<conversations.length;i++) { 
       let messages= await conversations[i].getMessages()  
       totalMessageCount += messages.items.filter(message => message.status === 'received' || message.status === 'new').length;
    
      };
      this.props.newMessageAdded(totalMessageCount); 
      if(!start)
      {
        let sound = new Audio(messageSound);
        sound.play();  
      }
    
  }
   showModal = () => {
    this.setState({ 
      isModalOpen: true
    }); 
  };

   handleOk = async () => {
    
    if(this.state.conversationName=="")
    {
      this.setState({
        addErrorMessage:true,
      })
    }
    else
    {
      this.setState({
        isModalOpen: false,
        spinning:true,
        addErrorMessage:false,
      })
      var response = await AddConversation({
          name: this.state.conversationName,
      });  
      this.setState({ 
        conversationName:"",
        spinning:false
      });  
    }
      
 
    
  };

   handleCancel = () => {
    this.setState({ 
      isModalOpen: false
    }); 
  };
  onConversationName = (event)=>{
    this.setState({
      conversationName:event.target.value
    })
  }
  render() {
     
    const { conversations, selectedConversationSid, status,stopSpinning } = this.state;
    const selectedConversation = conversations.find(
      (it) => it.sid === selectedConversationSid
    );

    let conversationContent;
    if (selectedConversation) {
      conversationContent = (
        <Conversation
          conversationProxy={selectedConversation}
          myIdentity={this.state.name}
        />
      );
    } else if (status !== "success") {
      conversationContent = (
        <div style={{width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "17px"}}>Loading your conversation...</div>
      ); 
    } else {
      conversationContent = "";
    }

    if (this.state.loggedIn) {
      return (
        <div className="conversations-window-wrapper">
    <Spin spinning={this.state.spinning} fullscreen style={{zIndex:1000}}/>
      <Modal okText="Save" title="Add New Conversation" open={this.state.isModalOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
        <ul style={{display:this.state.addErrorMessage?"block":"none",color:"red"}}><li> Please add conversation name.</li></ul>
      <Input
                            style={{flexBasis: "100%"}}
                            placeholder={"Name"}
                            type="text"  
                            autoComplete={"off"} 
                            onChange={this.onConversationName}
                            value={this.state.conversationName}
                        />
      </Modal>

          <Layout className="conversations-window-container">
            <Header
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <div 
              style={{display:this.state.isChatSideBarOpen?"flex":"none"}}
                id="chat-list-header"
                className="left-side-chat-header p-2"
              >   
              <FontAwesomeIcon onClick={this.toggaleSideBar} icon={faTimes} className="chat-close-icon" />

                    <h2 className="m-0">Chat</h2> 
                    <button onClick={this.showModal} className="button button-primary chat-add-convesation"  > Add New Conversation</button>
              </div>
              <div className="" style={{ display: "flex", width: "100%",alignItems: "center",justifyContent: "space-between" }}>
                <HeaderItem>
                  
                <div>
                  <Text strong   style={{fontSize: "22px"}} >
                    {selectedConversation &&
                      (selectedConversation.friendlyName || selectedConversation.sid)}
                  </Text>
                  <div>
                  <span
                     
                  >{` ${this.state.statusString}`}</span>
                  <Badge
                    dot={true}
                    status={this.state.status}
                    style={{ marginLeft: "1em" }}
                  /></div></div>
                </HeaderItem> 
                <FontAwesomeIcon onClick={this.toggaleSideBar} className="chat-bar-icon-close"   icon={faOutdent} />
              </div>
            </Header>
            <Layout>
              <Sider id="chat-list" style={{display:this.state.isChatSideBarOpen?"block":"none"}} theme={"light"} width={250}>
              <ConversationsList
                  conversations={conversations}
                  stopSpinning={stopSpinning}
                  selectedConversationSid={selectedConversationSid}
                  onConversationClick={(item) => {
                    this.setState({ selectedConversationSid: item.sid,isChatSideBarOpen:false });
                  }}
                />
              </Sider>
              <Content className="conversation-section">
                <div id="SelectedConversation">{conversationContent}</div>
              </Content>
            </Layout>
          </Layout>
        </div>
      );
    }
  }
}

export default ConversationsApp;
