
import Login from './components/Login/login';
import Chat from './components/Chat/Chat';
import User from './components/User/User';
import AddUser from './components/User/AddUser';
import ConversationsApp from './ChatComponant/ConversationsApp';
import Conversation from './components/Conversation/Conversation';
import AddEditConversation from './components/Conversation/AddConversation';
import Participant from './components/Participant/Participant';
import AddEditParticipant from './components/Participant/AddParticipant';
import SendMessage from './components/SendMessage/sendMessage';
//import Home from './components/Home';
import Layout from './components/Shared/Layout';
import Unauthorized from './components/Unauthorized';
import Constants from './Utils/Constants';
import RequireAuth from './RequireAuth';
import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';



function AppRoute() {
  const [messageCount,setMessageCount] = useState(0);
  const newMessageAdded=(count)=>{  
    setMessageCount(count)
  }
  return (
    <Routes>
      <Route path="/" >
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        <Route element={<Layout  messageCount={messageCount}/>}>
          <Route element={<RequireAuth allowedRoles={[Constants.Roles.Administrator]} />}>
            <Route path="/" element={<ConversationsApp />} />
            <Route path="/chat" element={<ConversationsApp newMessageAdded={newMessageAdded}  />} />
            <Route path="/user" element={<User />} />
            <Route path="/adduser/:id?" element={<AddUser />} />
            <Route path="/conversation" element={<Conversation />} />
            <Route path="/addConversation/:id?" element={<AddEditConversation />} />
            <Route path="/participant" element={<Participant />} />
            <Route path="/sendMessage" element={<SendMessage />} />
            <Route path="/addParticipent/:cid?/:sid?" element={<AddEditParticipant />} />

          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoute;