
import { removeAuth } from "../../hooks/useAuth"
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut, faTimes,faCommentDots, faUserCircle, faContactCard, faUsers, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { Badge } from 'primereact/badge';
const Sidebar = (prop) => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const Logout = () => {
        removeAuth();
        navigate("/login");
    }
    const clickLink = (e) => {
        navigate(e.currentTarget.getAttribute('data-column'));
        prop.onLinkClick();
    }
    
    return (
        <div className="sideBar" style={{display:prop.isSideBarOpen?"block":"none"}}>
           <FontAwesomeIcon onClick={prop.openCloseSidebar} className="sidebar-close-icon" icon={faTimes} />
            <div  className="sidebar-links sidebar-links-header ">
            <h1 className="mt-3 w-100" style={{color: "white",textAlign: "center"}}>SMS</h1>
            <hr className="m-0 w-100"/>
            </div>
            <div  className="sidebar-links mx-2">
                <div >
                <i className="sidebar-link icon" data-column="/chat" onClick={clickLink}><FontAwesomeIcon icon={faCommentDots} /><span>Chat</span>  <Badge id="chat-count" value={prop.messageCount??0} severity="danger"></Badge> </i>   
                </div><div>
                <i className="sidebar-link icon" data-column="/user" onClick={clickLink}><FontAwesomeIcon icon={faUserCircle} /><span>User</span></i>
                </div><div style={{    padding: "10px 10px"}}>
                <i style={{fontSize: "20px"}} className="sidebar-link icon" data-column="/conversation" onClick={clickLink}><FontAwesomeIcon icon={faUsers} /><span>Convesation</span></i>
                </div><div style={{    padding: "10px 10px"}}>
                <i style={{fontSize: "20px"}}  className="sidebar-link icon" data-column="/participant" onClick={clickLink}><FontAwesomeIcon icon={faContactCard} /><span>Participant</span></i>
                </div>

                <div style={{    padding: "10px 10px"}}>
                <i style={{fontSize: "20px"}}  className="sidebar-link icon"   onClick={Logout}><FontAwesomeIcon icon={faSignOut} /><span>Logout</span></i>
                </div>
            </div>
 

        </div>
    )
}
//    <i className="sidebar-link icon" data-column="/sendMessage" onClick={clickLink}><FontAwesomeIcon icon={faPaperPlane} /></i>
            

export default Sidebar;
