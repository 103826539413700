import { GetAllUsers, DeleteUserById } from "../../api/UserService"
import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { getRoleByRoleId } from '../../Utils/utils'
import Backdiv from '../Shared/Backdiv'
import Constants from "../../Utils/Constants";
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useToastContext } from "../../context/toast-context"
import { Spin } from "antd";
import { useNavigate } from 'react-router-dom';
const User = () => {
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const [users, setusers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const toastBC = useRef(null);

  useEffect(() => {

    async function getUsers() {
      setSpinning(true)
      const users = await GetAllUsers();
      const rows = users.map(user => {
        return {
          firstname: user.firstName,
          lastname: user.lastName,
          email: user.email,
          mobileno: user.mobileNo,
          role: user.userType,
          isActive: user.isActive,
          id: user.id
        }
      }
      );
      setusers(rows);
      setSpinning(false)
    }
    getUsers()
  }, []);


  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.isActive ? "Active" : "Inactive"} severity={rowData.isActive ? "success" : "danger"}></Tag>;
  };

  const getRole = (rowData) => {
    return getRoleByRoleId(rowData.role);
  }

  const addUserClick = () => {
    return navigate("/adduser", { replace: true });
  }

  const onEditbuttonClick = (e) => {
    return navigate("/adduser/" + e.currentTarget.getAttribute('data-id'), { replace: true });

  }
  const actionBodyTemplate = (e) => {
    return <div className="d-flex"><Button className="table-action " type="button" data-id={e.id} onClick={onEditbuttonClick}  
     rounded>Edit</Button><Button className="table-action ms-1 list-delete" type="button" data-id={e.id} onClick={confirmDelete} 
     rounded>Delete</Button></div>;
  };

  const clearDeletePopUp = () => {
    toastBC.current.clear();
    setVisible(false);
  };
  const deleteUser = async (e) => {

    let id = e.currentTarget.getAttribute('data-id');
    const response = await DeleteUserById(id);
    clearDeletePopUp()

    setusers(users.filter(obj => obj.id != id))
    if (response && response.isSuccess) {
      showToast({
        severity: "success",
        summary: response?.message
      })
    }
    else {
      showToast({
        severity: "error",
        summary: "Something went wrong. Please try again after sometime!!"
      })
    }
  };

  const confirmDelete = (e) => {
    let id = e.currentTarget.getAttribute('data-id')
    if (!visible) {
      setVisible(true);
      toastBC.current.clear();
      toastBC.current.show({
        severity: 'info',
        summary: 'Are you sure you want to delete the user?',
        sticky: true,
        content: (props) => (
          <div className=" flex flex-column align-items-left" style={{ flex: '1' }}>
            <div className="font-medium text-lg mb-3 text-900">{props.message.summary}</div>

            <Button className="p-button-sm flex" data-id={id} label="Ok" severity="success" onClick={deleteUser}></Button>
          </div>
        )
      });
    }
  };

  return <div className="p-3">
  <Spin spinning={spinning} fullscreen style={{zIndex:1000}}/>
    <Toast ref={toastBC} position="center" onRemove={clearDeletePopUp} />
    <div className="card mb-4 p-3">

      {visible && <Backdiv />}
      <div className="title-header">
        <h2>Users</h2>
        <span onClick={addUserClick} className="add-link"><FontAwesomeIcon style={{ "font-size": "20px" }} icon={faPlusCircle} /> Add User</span>
      </div>
      <DataTable className="datatable"  showGridlines paginator rows={Constants.Pagination.defaultPageSize} rowsPerPageOptions={Constants.Pagination.pageSizeOption} value={users}  >
        <Column field="firstname" sortable header="First Name"></Column>
        <Column field="lastname" sortable header="Last Name"></Column>
        <Column field="email" sortable header="Email"></Column>
        <Column field="mobileno" sortable header="Mobile No"></Column>
        <Column field="role" sortable header="Role" body={getRole}  ></Column>
        <Column field="isActive" sortable header="Status" body={statusBodyTemplate} ></Column>
        <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
      </DataTable>
    </div>
  </div>

}

export default User
