import { useLocation, Navigate, Outlet } from "react-router-dom";
import {getAuth} from "./hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => { 
    const auth  = getAuth();
    const location = useLocation(); 
    return (
        auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;