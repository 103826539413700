import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useState, useEffect } from 'react';
import { GetAllConversation, SendConversation } from "../../api/ConversationService"
import { useToastContext } from "../../context/toast-context"
import { useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
const SendMessage = () => {
    const { showToast } = useToastContext();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [conversationId, setConversationId] = useState("");
    const [conversations, setConversations] = useState([]);
    useEffect(() => {


        async function GetConversation() {
            const conversation = await GetAllConversation();
            setConversations(conversation.map(conversation => {
                return {
                    name: conversation.name,
                    id: conversation.id
                }
            }))
        }
        GetConversation();

    }, []);


    const submit = async (e) => {
        e.preventDefault(); 
        var response = await SendConversation({ id: conversationId.id, message: message });
        if (response) {
            setConversationId({});
            setMessage("");
            showToast({
                severity: "success",
                summary: "Message sent successfully!!"
            })
        }
        else {

            showToast({
                severity: "error",
                summary: "Message sent successfully!!"
            })
        }
    }
    return (

        <div className="add-user-cover p-3 d-flex justify-content-center">
            <div className="add-user-cover-card card mt-4 p-3" style={{ maxWidth: "625px" }}>
                <h2>Send Message</h2>
                <form onSubmit={submit} autoComplete="new-password">
                    <div className="row mt-1">
                        <div className="form-group mt-3 col-12">
                            <label  >Conversation </label>
                            <Dropdown required={true} value={conversationId} onChange={(e) => setConversationId(e.value)} options={conversations} optionLabel="name" placeholder="Select a conversation" className="w-100 mt-1" />
                        </div>

                        <div className="form-group mt-3 col-12">
                            <label  >Message</label>
                            <TextareaAutosize style={{ "border": "1px solid rgb(204, 204, 204)", "border-radius": "4px", "color": "#6b7280c2" }} value={message} className="form-control mt-1" onChange={(e) => setMessage(e.target.value)} placeholder="Write here...." minRows={5} maxRows={10} />
                        </div>

                        <div className="form-group mt-3 col-md-12">
                            <button className="button button-primary" >  Send</button>
                        </div>

                    </div>
                </form>
            </div>

        </div>)
}
//<Password value={value} onChange={(e) => setValue(e.target.value)} toggleMask />
export default SendMessage;