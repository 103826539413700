
import AppRoute from './App.route';




function App() {

  return (
  <AppRoute/>
  );
}

export default App;